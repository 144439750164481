<template>
  <button @click="setIsOpen(true)" class="btn btn-primary">
    Genereaza retrageri
  </button>
  <Dialog
    :open="isOpen"
    @close="setIsOpen"
    class="fixed inset-0 z-10 overflow-y-auto"
  >
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="min-h-screen px-4 text-center">
        <DialogOverlay class="fixed inset-0 bg-black opacity-30" />

        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div
          class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
        >
          <DialogTitle
            as="h3"
            class="text-lg font-medium leading-6 text-gray-900"
          >
            Esti sigur ca vrei sa generezi retrageri pentru toti vanzatorii?
          </DialogTitle>

          <div class="mt-4 flex justify-between">
            <button
              type="button"
              class="btn-primary"
              @click="generateWithdrawals"
            >
              Da, genereaza
            </button>
            <button type="button" class="btn" @click="setIsOpen(false)">
              Anuleaza
            </button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import vendorWithdrawalsApi from "../api/resources/vendorWithdrawalsApi";
import { ref, defineEmits } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

const emit = defineEmits(["index:withdrawals"]);

const isOpen = ref(false);

function generateWithdrawals() {
  vendorWithdrawalsApi
    .generateWithdrawals()
    .then(() => {
      emit("index:withdrawals");
      setIsOpen(false);
    })
    .catch((error) => console.log(error));
}

function setIsOpen(value) {
  isOpen.value = value;
}
</script>
