<template>
  <app-layout>
    <template v-slot:header> Retrageri vanzatori</template>
    <page-section>
      <page-section-body>
        <form class="flex" @submit.prevent="index">
          <div class="sm:w-40 mr-2 ml-0">
            <v-input type="date" v-model="query.created_at" />
          </div>
          <v-button class="self-end"> Filtreaza </v-button>
        </form>

        <v-card class="mt-2">
          <v-card-header>
            <template v-slot:actions>
              <modal-generate-withdrawal @index:withdrawals="index" />
            </template>
          </v-card-header>
          <loader v-if="loading" class="my-5" />
          <template v-else>
            <template v-if="totalRows">
              <vendor-withdrawals-index-table
                :rows="rows"
                @update:status="updateVendorWithdrawalStatus"
              />
              <pagination
                @change:page="changePage($event)"
                :total="meta.total"
                :current_page="meta.current_page"
                :from="meta.from"
                :to="meta.to"
                :next_page_url="links.next"
                :prev_page_url="links.prev"
              />
            </template>
            <no-results v-else class="m-5" />
          </template>
        </v-card>
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import vendorWithdrawalsApi from "../api/resources/vendorWithdrawalsApi";
import VendorWithdrawalsIndexTable from "../components/VendorWithdrawalsIndexTable.vue";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import VInput from "../components/ui/VInput.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";
import PageSection from "../components/ui/PageSection.vue";
import VCardHeader from "../components/ui/VCardHeader.vue";
import VButton from "../components/ui/VButton.vue";
import ModalGenerateWithdrawal from "../components/ModalGenerateWithdrawal.vue";

export default {
  components: {
    VendorWithdrawalsIndexTable,
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    VInput,
    PageSectionBody,
    PageSection,
    VCardHeader,
    VButton,
    ModalGenerateWithdrawal,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      query: {
        created_at: "",
        page: 1,
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    updateVendorWithdrawalStatus(vendorWithdrawal) {
      this.rows.forEach((row) => {
        if (
          row.id === vendorWithdrawal.id &&
          row.status.id !== vendorWithdrawal.status.id
        ) {
          this.index();
        }
      });
    },

    changePage(page) {
      this.query.page = page;
      this.index();
    },

    index() {
      this.loading = true;
      vendorWithdrawalsApi
        .index({ ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
