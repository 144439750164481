<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Data</v-table-th>
        <v-table-th>Suma</v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ new Date(row.created_at).toLocaleString() }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.amount) }}
        </v-table-td>
        <v-table-td>
          {{ row.status.name }}
        </v-table-td>
        <v-table-td>
          <modal-update-vendor-withdrawal-status
            :vendorWithdrawalId="row.id"
            @update:vendorWithdrawal="$emit('update:status', $event)"
          />
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import ModalUpdateVendorWithdrawalStatus from "@/components/ModalUpdateVendorWithdrawalStatus.vue";

export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
    ModalUpdateVendorWithdrawalStatus,
  },
  props: {
    rows: {
      type: Array,
    },
  },
  emits: ["update:status"],
  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
